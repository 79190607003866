<template>
  <div class="room__form">
    <vs-input label="Book type name" v-model="name"></vs-input>

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveBookType">Save</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'BookTypeForm',
  props: ['bookType'],
  data() {
    return {
      name: this.bookType.name,
      id: this.bookType.id,
    }
  },
  methods: {
    saveBookType: function() {
      const { name, id } = this
      this.$store
        .dispatch('books/saveBookTypeAction', {
          bookType: {
            name,
            id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: 'Book type saved',
            text: `Book type ${this.name} saved`,
            color: 'success',
            position: 'top-right',
          })
          this.$store.dispatch('books/getBookTypesAction')
          this.$emit('closePopup', this.closePopup)
        })
    },
  },
}
</script>
