<template>
  <div class="room__form">
    <vs-input label="Cause name" v-model="cause_name"></vs-input>

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveRoom">Save</vs-button>
    </div>
  </div>
</template>

<script>
import { iconsList } from "@/components/common/iconsList";

export default {
  name: "MissingCausesForm",
  props: ["cause"],
  data() {
    return {
      iconsList,
      cause_name: this.cause.name,
      cause_id: this.cause.id
    };
  },
  methods: {
    saveRoom: function() {
      this.$store
        .dispatch("teachers/saveMissingCausesAction", {
          cause: {
            name: this.cause_name,
            id: this.cause_id
          }
        })
        .then(() => {
          this.$vs.notify({
            title: "Missing cause saved",
            text: `Missing cause ${this.cause_name} saved`,
            color: "success",
            position: "top-right"
          });
          this.$emit("closePopup", this.closePopup);
        });
    }
  }
};
</script>

<style lang="scss">
.room__form > label {
  display: inline-block;
  width: 55px;
  height: 70px;
  margin: 2px;
  .vs-radio {
    display: inline-block;
  }
  .vs-radio--label {
    vertical-align: middle;
  }
  .vs-icon {
    max-width: 25px;
  }
}
.label {
  margin: 15px 0px 2px 7px;
  color: rgba(0, 0, 0, 0.7);
}
</style>