export const iconsList = [
    "star",
    "add_a_photo",
    "add_photo_alternate",
    "add_to_photos",
    "adjust",
    "assistant",
    "assistant_photo",
    "audiotrack",
    "blur_circular",
    "blur_linear",
    "blur_off",
    "blur_on",
    "brightness_1",
    "brightness_2",
    "brightness_3",
    "brightness_4",
    "brightness_5",
    "brightness_6",
    "brightness_7",
    "broken_image",
    "brush",
    "burst_mode",
    "camera",
    "camera_alt",
    "camera_front",
    "camera_rear",
    "camera_roll",
    "cases",
    "center_focus_strong",
    "center_focus_weak",
    "collections",
    "collections_bookmark",
    "color_lens",
    "colorize",
    "compare",
    "control_point",
    "control_point_duplicate",
    "crop",
    "crop_16_9",
    "crop_3_2",
    "crop_5_4",
    "crop_7_5",
    "crop_din",
    "crop_free",
    "crop_landscape",
    "crop_original",
    "crop_portrait",
    "crop_rotate",
    "crop_square",
    "dehaze",
    "details",
    "edit",
    "euro",
    "exposure",
    "exposure_neg_1",
    "exposure_neg_2",
    "exposure_plus_1",
    "exposure_plus_2",
    "exposure_zero",
    "face_retouching_natural",
    "filter",
    "filter_1",
    "filter_2",
    "filter_3",
    "filter_4",
    "filter_5",
    "filter_6",
    "filter_7",
    "filter_8",
    "filter_9",
    "filter_9_plus",
    "filter_b_and_w",
    "filter_center_focus",
    "filter_drama",
    "filter_frames",
    "filter_hdr",
    "filter_none",
    "filter_tilt_shift",
    "filter_vintage",
    "flare",
    "flash_auto",
    "flash_off",
    "flash_on",
    "flip",
    "flip_camera_android",
    "flip_camera_ios",
    "gradient",
    "grain",
    "grid_off",
    "grid_on",
    "hdr_enhanced_select",
    "hdr_off",
    "hdr_on",
    "hdr_strong",
    "hdr_weak",
    "healing",
    "image",
    "image_aspect_ratio",
    "image_not_supported",
    "image_search",
    "iso",
    "landscape",
    "leak_add",
    "leak_remove",
    "lens",
    "linked_camera",
    "looks",
    "looks_3",
    "looks_4",
    "looks_5",
    "looks_6",
    "looks_one",
    "looks_two",
    "loupe",
    "mic_external_off",
    "mic_external_on",
    "monochrome_photos",
    "movie_creation",
    "movie_filter",
    "mp",
    "music_note",
    "music_off",
    "nature",
    "nature_people",
    "navigate_before",
    "navigate_next",
    "palette",
    "panorama",
    "panorama_fish_eye",
    "panorama_horizontal",
    "panorama_horizontal_select",
    "panorama_photosphere",
    "panorama_photosphere_select",
    "panorama_vertical",
    "panorama_vertical_select",
    "panorama_wide_angle",
    "panorama_wide_angle_select",
    "photo",
    "photo_album",
    "photo_camera",
    "photo_camera_back",
    "photo_camera_front",
    "photo_filter",
    "photo_library",
    "photo_size_select_actual",
    "photo_size_select_large",
    "photo_size_select_small",
    "picture_as_pdf",
    "portrait",
    "receipt_long",
    "remove_red_eye",
    "rotate_90_degrees_ccw",
    "rotate_left",
    "rotate_right",
    "shutter_speed",
    "slideshow",
    "straighten",
    "style",
    "switch_camera",
    "switch_video",
    "tag_faces",
    "texture",
    "timelapse",
    "timer",
    "timer_10",
    "timer_3",
    "timer_off",
    "tonality",
    "transform",
    "tune",
    "view_comfy",
    "view_compact",
    "vignette",
    "wb_auto",
    "wb_cloudy",
    "wb_incandescent",
    "wb_iridescent",
    "wb_shade",
    "wb_sunny",
    "wb_twighlight",
    "grass"
]