<template>
  <div class="causes__content">
    <vs-list>
      <vs-list-item v-for="cause in causes" :key="cause.id" :title="cause.name">
        <vs-button
          :data-id="cause.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          @click="editCause"
        ></vs-button>

        <vs-button
          :data-id="cause.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          @click="deleteCause"
        ></vs-button>
      </vs-list-item>
    </vs-list>
    <div class="causes__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add cause</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="causes__popup"
        title="Add missing cause"
        :active.sync="popupActive"
      >
        <MissingCauseForm
          v-if="popupActive"
          :cause="selectedCause"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import MissingCauseForm from "@/components/settings/missingCauseForm";

export default {
  name: "causes",
  data() {
    return {
      selectedCause: {},
      popupActive: false
    };
  },
  components: { MissingCauseForm },
  computed: {
    ...mapState({
      causes: state => state.teachers.causes
    })
  },
  methods: {
    editCause: function(e) {
      this.selectedCause = this.causes.find(
        r => r.id == e.target.parentElement.dataset.id
      );
      this.popupActive = true;
    },
    deleteCause: function(e) {
      this.selectedCause = this.causes.find(
        r => r.id == e.target.parentElement.dataset.id
      );
      this.$store
        .dispatch("teachers/deleteMissingCauseAction", this.selectedCause.id)
        .then(() => {
          this.$vs.notify({
            title: "Removed",
            text: `Missing cause ${this.selectedCause.name} removed`,
            color: "success",
            position: "top-right"
          });
          this.selectedCause = {};
        })
        .catch(r => {
          this.$vs.notify({
            title: "Remove Error",
            text: r.message,
            color: "danger",
            position: "top-right"
          });
          this.selectedCause = {};
        });
      this.popupActive = false;
    },
    closePopup: function() {
      this.popupActive = false;
      this.selectedCause = {};
    },
    closePopupHandle: function() {
      this.selectedCause = {};
    }
  }
};
</script>
<style lang="scss">
.causes__content {
  max-width: 500px;
}
</style>
