<template>
  <div class="year__form">
    <div class="year__dates">
      <div class="start">
        <div class="year__date-picker">
          <label>School year start date</label>
          <v-date-picker v-model="startDate" is-inline />
        </div>
        <div class="year__date-picker">
          <label>School year end date</label>
          <v-date-picker v-model="endDate" is-inline />
        </div>
      </div>
    </div>
    <div class="year__save">
      <vs-button icon="save" @click="saveYear"> Save </vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SchoolYearForm",
  props: ["year"],
  data() {
    return {
      id: this.year.id,
      startDate:
        this.year && this.year.date_start_unix
          ? new Date(this.year.date_start_unix * 1000)
          : new Date(new Date().getFullYear(), 8, 1),
      endDate:
        this.year && this.year.date_end_unix
          ? new Date(this.year.date_end_unix * 1000)
          : null,
    };
  },
  methods: {
    saveYear: function () {
      this.$store
        .dispatch("years/saveYearAction", {
          id: this.id,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(() => {
          this.$vs.notify({
            title: "Year saved",
            text: `year ${this.startDate.getFullYear()}-${this.endDate.getFullYear()} saved`,
            color: "success",
            position: "top-right",
          });
          this.$emit("closePopup");
        });
    },
  },
};
</script>

<style lang="scss">
.year__date-picker {
  display: inline-block;
  margin: 10px;
  label {
    display: block;
    margin-bottom: 7px;
    color: var(--thead-text-color);
  }
}
.year__holidays {
  border-top: 1px solid #ccc;
  padding-top: 10px;
  margin-top: 10px;
}
</style>