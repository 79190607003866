<template>
  <div class="rooms__content">
    <vs-list>
      <vs-list-item
        v-for="room in rooms"
        :key="room.id"
        :icon="room.icon"
        :title="room.name"
      >
        <vs-button
          :data-id="room.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          @click="editRoom"
        ></vs-button>

        <vs-button
          :data-id="room.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          @click="deleteRoom"
        ></vs-button>
      </vs-list-item>
    </vs-list>
    <div class="rooms__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add Room</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="rooms__popup"
        title="Add room"
        :active.sync="popupActive"
      >
        <RoomForm
          v-if="popupActive"
          :room="selectedRoom"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import RoomForm from "@/components/settings/roomForm";

export default {
  name: "Rooms",
  data() {
    return {
      selectedRoom: {},
      popupActive: false,
    };
  },
  components: { RoomForm },
  computed: {
    ...mapState({
      rooms: (state) => state.rooms.rooms,
    }),
  },
  methods: {
    editRoom: function (e) {
      this.selectedRoom = this.rooms.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.popupActive = true;
    },
    deleteRoom: function (e) {
      this.selectedRoom = this.rooms.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.$store
        .dispatch("rooms/deleteRoomAction", {
          room: {
            id: this.selectedRoom.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: "Removed",
            text: `Room ${this.selectedRoom.name} removed`,
            color: "success",
            position: "top-right",
          });
          this.selectedRoom = {};
        })
        .catch((r) => {
          this.$vs.notify({
            title: "Remove Error",
            text: r.message,
            color: "danger",
            position: "top-right",
          });
          this.selectedRoom = {};
        });
      this.popupActive = false;
    },
    closePopup: function () {
      this.popupActive = false;
      this.selectedRoom = {};
    },
    closePopupHandle: function () {
      this.selectedRoom = {};
    },
  },
};
</script>
<style lang="scss">
.rooms__content {
  max-width: 500px;
}
</style>
