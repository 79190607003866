<template>
  <div class="rooms__content">
    <vs-list>
      <vs-list-item
        v-for="source in sources"
        :key="source.id"
        :title="source.source_name"
        :subtitle="`-- Added by ${source.owner.nickname}`"
      >
        <vs-button
          :data-id="source.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          @click="editSource"
        ></vs-button>

        <vs-button
          :data-id="source.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          @click="deleteSource"
        ></vs-button>
      </vs-list-item>
    </vs-list>
    <div class="source__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add Payment Source</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="sources__popup"
        title="Add source"
        :active.sync="popupActive"
        class="small-popup"
      >
        <SourceForm
          v-if="popupActive"
          :source="selectedSource"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import SourceForm from "@/components/settings/paymentSourceForm";

export default {
  name: "PaymentSources",
  data() {
    return {
      selectedSource: {},
      popupActive: false,
    };
  },
  components: { SourceForm },
  computed: {
    ...mapState({
      sources: (state) => state.payments.sources,
    }),
  },
  methods: {
    editSource: function (e) {
      this.selectedSource = this.sources.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.popupActive = true;
    },
    deleteSource: function (e) {
      this.selectedSource = this.sources.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.$store
        .dispatch("pauments/deleteSourceAction", {
          source: {
            id: this.selectedSource.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: "Removed",
            text: `Source ${this.selectedSource.name} removed`,
            color: "success",
            position: "top-right",
          });
          this.selectedSource = {};
        })
        .catch((r) => {
          this.$vs.notify({
            title: "Remove Error",
            text: r.message,
            color: "danger",
            position: "top-right",
          });
          this.selectedSource = {};
        });
      this.popupActive = false;
    },
    closePopup: function () {
      this.popupActive = false;
      this.selectedSource = {};
    },
    closePopupHandle: function () {
      this.selectedSource = {};
    },
  },
};
</script>
<style lang="scss">
</style>
