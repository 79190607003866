<template>
  <div class="settings">
    <vs-tabs>
      <vs-tab label="Group types">
        <div class="grouptypes">
          <GroupTypes @updateGroupTypes="updateGroupTypes" />
        </div>
      </vs-tab>
      <vs-tab label="School years">
        <div class="schoolyears">
          <Years />
        </div>
      </vs-tab>

      <vs-tab label="Rooms settings">
        <div class="rooms">
          <Rooms />
        </div>
      </vs-tab>
      <vs-tab label="Surprise categories">
        <div class="categories">
          <categories />
        </div>
      </vs-tab>
      <vs-tab label="Missing causes">
        <div class="causes">
          <Causes />
        </div>
      </vs-tab>
      <vs-tab label="Pay sources">
        <div class="pay-sources">
          <PaymentSourceTypes />
        </div>
      </vs-tab>
      <vs-tab label="Book types">
        <div class="book-types">
          <BookTypes />
        </div>
      </vs-tab>
    </vs-tabs>
  </div>
</template>

<script>
// @ is an alias to /src
import Rooms from '@/components/settings/rooms.vue'
import Years from '@/components/settings/schoolyears.vue'
import Causes from '@/components/settings/missingCauses.vue'
import GroupTypes from '@/components/settings/groupTypesList.vue'
import PaymentSourceTypes from '@/components/settings/paymentSources.vue'
import Categories from '../components/settings/categories.vue'
import BookTypes from '../components/settings/bookTypes.vue'

export default {
  name: 'Settings',
  title: 'SE-Settings',

  data() {
    return {
      rooms: [],
    }
  },
  components: {
    Rooms,
    Years,
    GroupTypes,
    Causes,
    PaymentSourceTypes,
    Categories,
    BookTypes,
  },
  mounted() {
    this.$store.dispatch('rooms/getRoomsAction').then(() => {})
    this.$store.dispatch('years/getYearsAction').then(() => {})
    this.$store.dispatch('groups/getGroupTypesAction').then(() => {})
    this.$store.dispatch('teachers/getMissingCausesAction').then(() => {})
    this.$store.dispatch('payments/getSourcesAction').then(() => {})
    this.$store.dispatch('surprises/getCategoriesAction').then(() => {})
    this.$store.dispatch('surprises/getCategoriesAction').then(() => {})
    this.$store.dispatch('books/getBookTypesAction').then(() => {})
  },
  methods: {
    updateGroupTypes: function() {
      this.$store.dispatch('groups/getGroupTypesAction').then(() => {})
    },
  },
}
</script>
<style lang="scss">
.settings {
  h2 {
    margin-top: 20px;
  }
}
</style>
