<template>
  <div class="group-type__form">
    <form @submit="saveGroupType">
      <vs-input label="Group type name" v-model="name"></vs-input>
      <vs-input-number
        v-model="duration"
        step="10"
        label="lesson duration in minutes:"
      />
      <vs-input-number v-model="price" step="50" label="lesson price:" />
      <vs-input-number v-model="students_count" label="max student count:" />
      <vs-input-number
        v-model="lessons_count"
        label="number of lessons per week:"
      />
      <vs-checkbox v-model="evening_increase"
        >+100 для вечерних занятий</vs-checkbox
      >
      <vs-select
        description-text="Tutor or With fixed rate"
        label="Advanced type"
        v-model="advanced"
      >
        <vs-select-item
          :key="index"
          :value="item.value"
          :text="item.text"
          v-for="(item, index) in advancedTypes"
        />
      </vs-select>

      <vs-input-number
        v-model="fixed_rate"
        v-if="advanced == 'with_fixed_rate'"
        step="10"
        label="fixed rate:"
      />
      <div class="save-popup-button">
        <vs-button icon="save" @click="saveGroupType">Save</vs-button>
      </div>
    </form>
  </div>
</template>

<script>
import { mapState } from 'vuex'
export default {
  name: 'GroupTypeForm',
  props: ['groupType'],
  data() {
    let data = {
      name: '',
      duration: 0,
      price: 0,
      students_count: 0,
      lessons_count: 0,
      evening_increase: true,
      fixed_rate: 0,
      advanced: null,
      advancedTypes: [
        {
          value: null,
          text: '-',
        },
        {
          value: 'is_tutor',
          text: 'Tutor',
        },
        {
          value: 'with_fixed_rate',
          text: 'With fixed rate',
        },
      ],
    }
    if (this.groupType.id) {
      data = { ...data, ...this.groupType }
    }
    console.log(this.groupType)
    if (this.groupType.with_fixed_rate) data.advanced = 'with_fixed_rate'
    if (this.groupType.is_tutor) data.advanced = 'is_tutor'
    console.log(data)
    return data
  },
  computed: {
    ...mapState({
      grouptypes: state => state.groups.groupTypes,
    }),
  },
  methods: {
    saveGroupType: function() {
      const {
        id,
        name,
        duration,
        price,
        students_count,
        lessons_count,
        evening_increase,
        fixed_rate,
        advanced,
      } = this
      const groupType = {
        name,
        duration,
        price,
        students_count,
        lessons_count,
        id,
        evening_increase,
        fixed_rate,
      }
      // Отправляем тип с абонементом
      advanced == 'with_fixed_rate' ? (groupType.with_fixed_rate = true) : null
      // Отправляем тип как инд.группа
      advanced == 'is_tutor' ? (groupType.is_tutor = true) : null

      if (!id) {
        groupType.weight = this.grouptypes.length + 1
      }

      this.$store
        .dispatch('groups/setGroupTypesAction', { groupType: [groupType] })
        .then(() => {
          this.$vs.notify({
            title: 'Group type saved',
            text: `Group type ${this.name} saved`,
            color: 'success',
            position: 'top-right',
          })
          this.$emit('closePopup', this.closePopup)
        })
    },
  },
}
</script>

<style lang="scss">
.group-type__form {
  & > .vs-input {
    margin-bottom: 15px;
    width: 60%;
  }
  .vs-input-number {
    justify-content: flex-start;
    & > span {
      display: inline-block;
      width: 250px;
    }
  }
}
</style>
