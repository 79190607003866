<template>
  <div class="grouptypes__content">
    <div class="grouptypes__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add Group type</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="grouptypes__popup"
        title="Add group type"
        :active.sync="popupActive"
      >
        <GroupTypeForm
          v-if="popupActive"
          :groupType="selectedGroupType"
          @closePopup="closePopupEvent"
        />
      </vs-popup>
    </div>
    <draggable v-model="groupTypes" @end="endMove">
      <div
        class="group-type__item list-item"
        v-for="groupType in groupTypes"
        :key="groupType.id"
      >
        <div class="group-type__move" title="move">
          <vs-icon icon="drag_handle"></vs-icon>
        </div>
        <div class="group-type__name">
          <div class="group-type__name__name">
            {{ groupType.name }}
          </div>
          <div class="group-type__name__fixed-rate" v-if="groupType.fixed_rate">
            {{ `Fixed rate: ${groupType.fixed_rate}` }}
          </div>
          <div class="group-type__name__tutor" v-if="groupType.is_tutor">
            <vs-chip transparent color="primary"
              ><vs-avatar icon="info" />Tutor</vs-chip
            >
          </div>
        </div>
        <div class="group-type__manage">
          <vs-button
            :data-id="groupType.id"
            radius
            color="dark"
            icon="edit"
            type="flat"
            @click="editGroupType(groupType)"
          ></vs-button>

          <vs-button
            :data-id="groupType.id"
            radius
            color="dark"
            icon="delete"
            type="flat"
            @click="deleteGroupType(groupType)"
          ></vs-button>
        </div>
      </div>
    </draggable>
    <!-- <vs-list>
      <vs-list-item
        v-for="groupType in grouptypes"
        :key="groupType.id"
        :title="groupType.name"
        :subtitle="
          groupType.with_fixed_rate ? `Fixed rate: ${groupType.fixed_rate}` : ''
        "
      >
        <vs-button
          :data-id="groupType.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          @click="editGroupType(groupType)"
        ></vs-button>

        <vs-button
          :data-id="groupType.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          @click="deleteGroupType(groupType)"
        ></vs-button>
      </vs-list-item>
    </vs-list> -->
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import draggable from 'vuedraggable'

import GroupTypeForm from '@/components/settings/groupTypeForm'

export default {
  name: 'grouptypes',
  // props: ["grouptypes"],
  data() {
    return {
      selectedGroupType: {},
      groupTypes: [],
      popupActive: false,
    }
  },
  components: { GroupTypeForm, draggable },
  computed: {
    ...mapState({
      grouptypes: state => state.groups.groupTypes,
    }),
  },
  methods: {
    editGroupType: function(groupType) {
      this.selectedGroupType = groupType
      this.popupActive = true
    },
    deleteGroupType: function(groupType) {
      this.selectedGroupType = groupType
      this.$store
        .dispatch('groups/deleteGroupTypeAction', {
          groupType: {
            id: this.selectedGroupType.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: 'Removed',
            text: `Group type ${this.selectedGroupType.name} removed`,
            color: 'success',
            position: 'top-right',
          })
          this.selectedGroupType = {}
        })
        .catch(r => {
          this.$vs.notify({
            title: 'Remove Error',
            text: r.message,
            color: 'danger',
            position: 'top-right',
          })
          this.selectedGroupType = {}
        })
      this.popupActive = false
    },
    closePopupEvent: function() {
      this.popupActive = false
      this.selectedGroupType = {}
    },
    closePopupHandle: function() {
      this.selectedGroupType = {}
    },
    endMove: function() {
      // сохраняем все
      const groupType = this.groupTypes.map((t, weight) => {
        const { id } = t
        return {
          id,
          weight,
        }
      })

      this.$store
        .dispatch('groups/setGroupTypesAction', { groupType })
        .then(() => {
          this.$vs.notify({
            title: 'Group type saved',
            text: `Group type saved`,
            color: 'success',
            position: 'top-right',
          })
          this.$emit('updateGroupTypes')
        })
    },
  },
  watch: {
    grouptypes: function() {
      this.groupTypes = [...this.grouptypes]
    },
  },
  created() {
    this.groupTypes = [...this.grouptypes]
  },
}
</script>
<style lang="scss">
.grouptypes__content {
  max-width: 500px;
}
.group-type__item {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  padding: 5px;
  border-bottom: 1px solid #ddd;
  .group-type__move {
    cursor: move;
    font-size: 1rem;
    margin-right: 1rem;
  }
  .group-type__name {
    flex: 1;
    &__fixed-rate {
      font-size: 0.8rem;
      color: var(--sub-color);
    }
    &__name {
      margin: 3px 0;
    }
  }
}
</style>
