<template>
  <div class="source__form">
    <vs-input label="Source name" v-model="source_name"></vs-input>
    <vs-input label="Source synonyms" v-model="source_synonyms"></vs-input>

    <div class="save-popup-button">
      <vs-button icon="save" @click="saveRoom">Save</vs-button>
    </div>
  </div>
</template>

<script>
export default {
  name: "SourceForm",
  props: ["source"],
  data() {
    const { source_name, source_synonyms, id } = this.source;
    return {
      source_name,
      source_synonyms,
      id,
    };
  },
  methods: {
    saveRoom: function () {
      this.$store
        .dispatch("payments/saveSourceAction", {
          source: {
            source_name: this.source_name,
            source_synonyms: this.source_synonyms,
            id: this.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: "Source saved",
            text: `Source ${this.source_name} saved`,
            color: "success",
            position: "top-right",
          });
          this.$emit("closePopup", this.closePopup);
        });
    },
  },
};
</script>

<style lang="scss">
</style>