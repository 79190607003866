<template>
  <div class="category__form">
    <vs-input label="Category name" v-model="name"></vs-input>
    <div class="field">
      <compact-picker label="Group color" v-model="color" />
    </div>
    <div class="label">Category icon</div>
    <vs-radio
      v-model="icon"
      vs-name="radios1"
      :vs-value="item"
      :key="index"
      v-for="(item, index) in iconsList"
    >
      <vs-icon :icon="item"></vs-icon>
    </vs-radio>
    <div class="save-popup-button">
      <vs-button icon="save" @click="save">Save</vs-button>
    </div>
  </div>
</template>

<script>
import { iconsList } from "@/components/common/iconsList";
import { Slider } from "vue-color";

export default {
  name: "CategoryForm",
  props: ["category"],
  data() {
    let data = {
      iconsList,
      name: null,
      icon: null,
      color: "#fff",
      id: null,
    };
    if (this.category) {
      data = { ...data, ...this.category };
    }
    return data;
  },
  components: {
    "compact-picker": Slider,
  },
  methods: {
    save: function () {
      let { name, icon, color, id } = this;
      color = color.hex ? color.hex : color;
      this.$store
        .dispatch("surprises/saveCategoryAction", {
          category: {
            name,
            icon,
            id,
            color,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: "Category saved",
            text: `Category ${this.name} saved`,
            color: "success",
            position: "top-right",
          });
          this.$emit("closePopup", this.closePopup);
        });
    },
  },
};
</script>

<style lang="scss">
.category__form > label {
  display: inline-block;
  width: 55px;
  height: 70px;
  margin: 2px;
  .vs-radio {
    display: inline-block;
  }
  .vs-radio--label {
    vertical-align: middle;
  }
  .vs-icon {
    max-width: 25px;
  }
}
.label {
  margin: 15px 0px 2px 7px;
  color: rgba(0, 0, 0, 0.7);
}
</style>