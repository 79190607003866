<template>
  <div class="room__form">
    <vs-input label="Room name" v-model="room_name"></vs-input>
    <div class="label">Room icon</div>
    <vs-radio
      v-model="room_icon"
      vs-name="radios1"
      :vs-value="item"
      :key="index"
      v-for="(item,index) in iconsList"
    >
      <vs-icon :icon="item"></vs-icon>
    </vs-radio>
    <div class="save-popup-button">
      <vs-button icon="save" @click="saveRoom">Save</vs-button>
    </div>
  </div>
</template>

<script>
import { iconsList } from "@/components/common/iconsList";

export default {
  name: "RoomForm",
  props: ["room"],
  data() {
    return {
      iconsList,
      room_name: this.room.name,
      room_icon: this.room.icon,
      room_id: this.room.id
    };
  },
  methods: {
    saveRoom: function() {
      this.$store
        .dispatch("rooms/saveRoomAction", {
          room: {
            name: this.room_name,
            icon: this.room_icon,
            id: this.room_id
          }
        })
        .then(() => {
          this.$vs.notify({
            title: "Room saved",
            text: `Room ${this.room_name} saved`,
            color: "success",
            position: "top-right"
          });
          this.$emit("closePopup", this.closePopup);
        });
    }
  },
  created() {
    console.log(this.room);
  }
};
</script>

<style lang="scss">
.room__form > label {
  display: inline-block;
  width: 55px;
  height: 70px;
  margin: 2px;
  .vs-radio {
    display: inline-block;
  }
  .vs-radio--label {
    vertical-align: middle;
  }
  .vs-icon {
    max-width: 25px;
  }
}
.label {
  margin: 15px 0px 2px 7px;
  color: rgba(0, 0, 0, 0.7);
}
</style>