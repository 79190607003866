<template>
  <div class="holidays__form">
    <vs-progress indeterminate color="primary" v-if="saving"
      >primary</vs-progress
    >

    <div class="holidays__item" v-for="(h, index) in holidays" :key="index">
      <div class="name">
        <vs-input
          label-placeholder="Holiday name"
          v-model="holidays[index].holiday_type"
        ></vs-input>
      </div>
      <div class="date-range">
        <v-date-picker
          v-model="holidays[index].range"
          is-range
          :masks="pickerMasks"
        >
          <template v-slot="{ inputValue, inputEvents }">
            <vs-input
              :value="inputValue.start"
              class="inline-datepicker"
              readonly
              v-on="inputEvents.start"
              label-placeholder="Start Date"
            />
            <vs-icon icon="arrow_right_alt" class="inline-datepicker"></vs-icon>
            <vs-input
              :value="inputValue.end"
              class="inline-datepicker"
              readonly
              v-on="inputEvents.end"
              label-placeholder="End Date"
            />
          </template>
        </v-date-picker>
      </div>
      <div class="delete-holiday">
        <vs-button
          icon="delete"
          type="flat"
          radius
          @click="deleteHoliday(index)"
          color="dark"
        ></vs-button>
      </div>
    </div>
    <vs-button
      type="flat"
      icon="add"
      @click="addHoliday"
      class="button-no-float"
    >
      Add Holiday
    </vs-button>
    <vs-button
      icon="save"
      @click="saveHolidays"
      class="save-button button-no-float"
      :disabled="saving"
    >
      Save
    </vs-button>
  </div>
</template>

<script>
export default {
  name: "SchoolYearForm",
  props: ["year"],
  data() {
    return {
      saving: false,
      holidays: [],
      template: {
        holiday_type: "",
        range: {
          start: new Date(),
          end: new Date(),
        },
      },
      pickerMasks: {
        input: "DD/MM/YYYY",
      },
    };
  },
  computed: {
    yearStartDate: function () {
      return new Date(this.year.date_start_unix * 1000);
    },
    yearEndDate: function () {
      return new Date(this.year.date_end_unix * 1000);
    },
  },
  methods: {
    addHoliday: function () {
      this.holidays.push({ ...this.template });
    },
    deleteHoliday: function (index) {
      this.holidays.splice(index, 1);
    },
    getHolidays: function () {
      this.$store
        .dispatch("years/getHolidaysAction", {
          schoolyear_id: this.year.id,
        })
        .then((data) => {
          this.holidays = data.map((d) => {
            return {
              ...d,
              range: {
                start: new Date(d.range.start * 1000),
                end: new Date(d.range.end * 1000),
              },
            };
          });
        });
    },
    saveHolidays: function () {
      this.saving = true;
      const holidays = this.holidays.map((h) => {
        return {
          ...h,
          range: {
            start: h.range.start.getTime(),
            end: h.range.end.getTime(),
          },
        };
      });
      this.$store
        .dispatch("years/saveHolidaysAction", {
          schoolyear_id: this.year.id,
          holidays,
        })
        .then(() => {
          this.$emit("closePopup", this.closePopup);
          this.$vs.notify({
            title: "Holidays saved",
            text: `Holidays saved and lessons in all groups verified`,
            color: "success",
            position: "top-right",
          });
          this.saving = false;
        })
        .catch(() => {
          this.saving = false;
          this.$vs.notify({
            title: "Holidays not saved",
            text: `Holidays ${this.yearStartDate.getFullYear()}-${this.yearEndDate.getFullYear()} not saved`,
            color: "danger",
            position: "top-right",
          });
        });
    },
  },
  created() {
    this.getHolidays();
  },
};
</script>

<style lang="scss">
.holidays__item {
  display: flex;
  flex-flow: row nowrap;
  padding: 15px;
  margin-bottom: 10px;
  background-color: #eee;
  border-radius: 10px;
  align-items: flex-end;
  & > div {
    margin-right: 15px;
  }
  .date-range {
    .inline-datepicker {
      display: inline-block;
      vertical-align: middle;
      &.vs-input {
        width: 100px;
      }
    }
  }
}
.save-button {
  margin-top: 15px;
}
.button-no-float {
  float: none !important;
}
</style>