<template>
  <div class="categories__content">
    <vs-list>
      <vs-list-item
        v-for="category in categories"
        :key="category.id"
        :title="category.name"
      >
        <template slot="avatar">
          <vs-avatar :icon="category.icon" :color="category.color"></vs-avatar>
        </template>
        <vs-button
          :data-id="category.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          @click="editCategory"
        ></vs-button>

        <vs-button
          :data-id="category.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          @click="deleteCategory"
        ></vs-button>
      </vs-list-item>
    </vs-list>
    <div class="categories__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add category</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="categories__popup"
        title="Add category"
        :active.sync="popupActive"
        v-if="popupActive"
      >
        <CategoryForm
          v-if="popupActive"
          :category="selectedCategory"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import CategoryForm from "@/components/settings/categoryForm";

export default {
  name: "Categories",
  data() {
    return {
      selectedCategory: {},
      popupActive: false,
    };
  },
  components: { CategoryForm },
  computed: {
    ...mapState({
      categories: (state) => state.surprises.categories,
    }),
  },
  methods: {
    editCategory: function (e) {
      this.selectedCategory = this.categories.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.popupActive = true;
    },
    deleteCategory: function (e) {
      this.selectedCategory = this.categories.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.$store
        .dispatch("surprises/deleteCategoryAction", {
          category: {
            id: this.selectedCategory.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: "Removed",
            text: `Category ${this.selectedCategory.name} removed`,
            color: "success",
            position: "top-right",
          });
          this.selectedCategory = {};
        })
        .catch((r) => {
          this.$vs.notify({
            title: "Remove Error",
            text: r.message,
            color: "danger",
            position: "top-right",
          });
          this.selectedCategory = {};
        });
      this.popupActive = false;
    },
    closePopup: function () {
      this.popupActive = false;
      this.selectedCategory = {};
    },
    closePopupHandle: function () {
      this.selectedCategory = {};
    },
  },
};
</script>
<style lang="scss">
.categories__content {
  max-width: 500px;
}
</style>
