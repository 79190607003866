<template>
  <div class="book-type__content">
    <vs-list>
      <vs-list-item
        v-for="bookType in bookTypes"
        :key="bookType.id"
        :title="bookType.name"
      >
        <vs-button
          :data-id="bookType.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          @click="editBookType"
        ></vs-button>

        <vs-button
          :data-id="bookType.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          @click="deleteBookType"
        ></vs-button>
      </vs-list-item>
    </vs-list>
    <div class="book-type__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add book type</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="book-type__popup"
        title="Book type"
        :active.sync="popupActive"
      >
        <BookTypeForm
          v-if="popupActive"
          :bookType="selected"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from 'vuex'
import BookTypeForm from './bookTypeForm.vue'

export default {
  name: 'bookTypesComponent',
  data() {
    return {
      selected: {},
      popupActive: false,
    }
  },
  components: { BookTypeForm },
  computed: {
    ...mapState({
      bookTypes: state => state.books.bookTypes,
    }),
  },
  methods: {
    editBookType: function(e) {
      this.selected = this.bookTypes.find(
        r => r.id == e.target.parentElement.dataset.id
      )
      this.popupActive = true
    },
    deleteBookType: function(e) {
      this.selected = this.bookTypes.find(
        r => r.id == e.target.parentElement.dataset.id
      )
      this.$store
        .dispatch('books/deleteBookTypeAction', this.selected.id)
        .then(() => {
          this.$vs.notify({
            title: 'Removed',
            text: `Book type ${this.selected.name} removed`,
            color: 'success',
            position: 'top-right',
          })
          this.selected = {}
          this.$store.dispatch('books/getBookTypesAction')
        })
        .catch(r => {
          this.$vs.notify({
            title: 'Remove Error',
            text: r.message,
            color: 'danger',
            position: 'top-right',
          })
          this.selected = {}
        })
      this.popupActive = false
    },
    closePopup: function() {
      this.popupActive = false
      this.selected = {}
    },
    closePopupHandle: function() {
      this.selected = {}
    },
  },
  watch: {
    bookTypes: function(newV) {
      console.log(newV, 'update')
    },
  },
}
</script>
<style lang="scss">
.book-type__content {
  max-width: 500px;
}
</style>
