<template>
  <div class="year__content">
    <vs-list>
      <vs-list-item
        v-for="year in years"
        :key="year.id"
        icon="date_range"
        :title="year.name"
        :subtitle="`${year.date_start}-${year.date_end}`"
      >
        <vs-button
          :data-id="year.id"
          radius
          color="dark"
          icon="event_note"
          type="flat"
          @click="editHolidays(year.id)"
          title="Edit holidays"
        ></vs-button>

        <vs-button
          :data-id="year.id"
          radius
          color="dark"
          icon="edit"
          type="flat"
          title="Edit schoolyear"
          @click="editYear(year.id)"
        ></vs-button>

        <vs-button
          :data-id="year.id"
          radius
          color="dark"
          icon="delete"
          type="flat"
          title="Delete schoolyear"
          @click="deleteYear"
        ></vs-button>
      </vs-list-item>
    </vs-list>
    <div class="year__form">
      <vs-button color="primary" type="flat" @click="popupActive = true"
        >+ Add Year</vs-button
      >
      <vs-popup
        @close="closePopupHandle"
        classContent="year__popup"
        title="Add year"
        :active.sync="popupActive"
      >
        <YearsForm
          v-if="popupActive"
          :year="selectedYear"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
    <div class="holidays">
      <vs-popup
        @close="closePopupHandle"
        classContent="holidays__popup"
        title="Add holidays"
        :active.sync="holidaysPopupActive"
      >
        <HolidaysForm
          v-if="holidaysPopupActive"
          :year="selectedYear"
          @closePopup="closePopup"
        />
      </vs-popup>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { mapState } from "vuex";
import YearsForm from "@/components/settings/schoolyearForm";
import HolidaysForm from "@/components/settings/holidaysForm";

export default {
  name: "SchoolYears",
  data() {
    return {
      selectedYear: {},
      popupActive: false,
      holidaysPopupActive: false,
    };
  },
  components: {
    YearsForm,
    HolidaysForm,
  },
  computed: {
    ...mapState({
      years: (state) => state.years.years,
    }),
  },
  methods: {
    editYear: function (yearId) {
      this.selectedYear = this.years.find((r) => r.id == yearId);
      this.popupActive = true;
    },
    deleteYear: function (e) {
      this.selectedYear = this.years.find(
        (r) => r.id == e.target.parentElement.dataset.id
      );
      this.$store
        .dispatch("years/deleteYearAction", {
          year: {
            id: this.selectedYear.id,
          },
        })
        .then(() => {
          this.$vs.notify({
            title: "Removed",
            text: `Year ${this.selectedYear.name} removed`,
            color: "success",
            position: "top-right",
          });
          this.selectedYear = {};
        })
        .catch((r) => {
          this.$vs.notify({
            title: "Remove Error",
            text: r.message,
            color: "danger",
            position: "top-right",
          });
          this.selectedYear = {};
        });
      this.popupActive = false;
    },
    closePopup: function () {
      this.popupActive = false;
      this.holidaysPopupActive = false;
      this.selectedYear = {};
    },
    closePopupHandle: function () {
      this.selectedYear = {};
    },
    editHolidays: function (yearId) {
      this.selectedYear = this.years.find((r) => r.id == yearId);
      this.holidaysPopupActive = true;
    },
  },
};
</script>
<style lang="scss">
.year__content {
  max-width: 500px;
}
</style>
